.s006 {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  background-color: #42bfd7;
  background-position: center center;
  padding: 15px;
}

.s006 form {
  width: 100%;
  max-width: 790px;
}

.s006 form legend {
  font-size: 36px;
  color: #fff;
  font-weight: 800;
  text-align: center;
  margin-bottom: 30px;
}

.code-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.code {
  flex-grow: 1;
  border-radius: 5px;
  font-size: 75px;
  line-height: 75px;
  height: 120px;
  width: 100px;
  border: 1px solid #eee;
  outline-width: thin;;
  outline-color: #ddd;
  margin: 1%;
  text-align: center;
  font-weight: 300;
  -moz-appearance: textfield;
  margin-left: 10px;
  padding: 0;
}

.code:focus::placeholder {
  color: transparent;
}

.btn-search:hover, .btn-search:focus {
  outline: 0;
  box-shadow: none;
}

.btn-search:hover svg, .btn-search:focus svg {
  fill: #666;
}

.btn-search svg {
  fill: #ccc;
  width: 50px;
  height: 50px;
  transition: all .2s ease-out, color .2s ease-out;
}

.btn-search {
  #flex-grow: 1;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  height: 120px;
  min-width: 100px;
  background-color: green;
  padding: 0;
}

.code::-webkit-outer-spin-button,
.code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.code:valid {
  border-color: #1DBF73;
  box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
}

.info {
  background-color: #eaeaea;
  display: inline-block;
  padding: 10px;
  line-height: 20px;
  max-width: 400px;
  color: #777;
  border-radius: 5px;
}

@media (min-width:630px) and (max-width:820px) {
  .code {
    font-size: 60px;
    line-height: 60px;
    height: 80px;
    max-width: 70px;
  }

  .btn-search {
    height: 80px;
    min-width: 70px;
  }
}

@media (min-width:455px) and (max-width:630px) {
  .code {
    font-size: 50px;
    line-height: 50px;
    height: 60px;
    max-width: 50px;
  }

  .btn-search {
    height: 60px;
    min-width: 50px;
  }

  .btn-search svg {
    width: 30px;
    height: 30px;
  }
}

@media (min-width:380px) and (max-width:455px) {
  .code {
    font-size: 40px;
    line-height: 40px;
    height: 50px;
    max-width: 40px;
  }

  .btn-search {
    height: 50px;
    min-width: 40px;
  }

  .btn-search svg {
    width: 30px;
    height: 30px;
  }

  .s006 form legend {
    font-size: 25px;
  }
}

@media (max-width:380px) {
  .code-container {
    flex-wrap: wrap;
  }

  .code {
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 40px;
    height: 50px;
    max-width: 40px;
  }

  .btn-search {
    margin-bottom: 10px;
    height: 50px;
    min-width: 40px;
  }

  .btn-search svg {
    width: 30px;
    height: 30px;
  }

  .s006 form legend {
    font-size: 25px;
  }
}

.logoContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.nb {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
}

.dropdown {
  position: absolute;
  top: 20px;
  right: 20px;
}
