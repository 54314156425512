.Comment-Box {
  max-width: 80%;
  background-color: white;
  position: absolute;
  top: 20px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 9px 18px 30px 4px rgba(0,0,0,0.23);
}

.Diaporama-Image {
  max-width: 100%;
  max-height: 100%;
}

.Toastify__toast-container--top-right{
  margin-right: -200px
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.h1{
  font-size: 2.5rem;
  font-family: 'Permanent Marker', cursive;
}

.canvas-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.errorContainer{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding: 0 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000000;
  text-align: center;
  color: #E0E0E0;
  font-family: 'Fira Mono', monospace;
}

.animationContainer{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.icon-wrapper {
  border-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.loading-container {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: black;
}

.overlay-button {
  top: 15px;
  right: 15px;
  position: absolute;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  border: none;
  background-color: darkorange;
}

::-webkit-scrollbar {display:none;}
